import { useI18n } from 'vue-i18n';

const usePriceFormat = () => {
  const { t } = useI18n();

  const cleanPrice = (price) => {
    if (!price) return { cleanedPrice: '0' };
    const separator = typeof price === 'string' ? ',' : '.';
    const cleanedPrice = price.toString().replace(/[^0-9.,]/g, '');
    return { cleanedPrice, separator };
  }

  const formatInteger = (value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, (c) => `.${c}`);

  const formatFractional = (value) => {
    if (!value) return '00';
    if (value.length === 1) return `${value}0`;
    return value.substring(0, 2);
  };

  const itsFree = (value) => /^0$/.test(value)

  const getFormattedPrice = (price, isCollection) => {    
    if(!price) return '';
    const { cleanedPrice, separator } = cleanPrice(price);

    const [integerPart, fractionalPart] = cleanedPrice.split(separator);
    const integerPrice = formatInteger(integerPart);
    const floatingPrice = formatFractional(fractionalPart);

    const currency = '€';
    const isExactPrice = floatingPrice === '00';
    const isFreePrice = itsFree(integerPrice);
    const from = isCollection ? `${t('global.from')} ` : '';

    if (!isExactPrice) return `${from}${integerPrice},${floatingPrice} ${currency}`;
    if (isFreePrice) return t('global.free');
    return `${from}${integerPrice} ${currency}`;
  };
  
  /**
   * Formats a price object into a human-readable string, including sale prices and discounts.
   * @param {Object} price - The price object to be formatted.
   * @returns {string} - The formatted price string.
   * @example
  * // Example usage:
  * const priceObject = {
  *   unit_sale_price: 279,
  *   unit_price: 329,
  *   discount: 15,
  *   onsale: true
  * };
  * const formattedPrice = getFormattedPriceReadable(priceObject);
  * // Result: "Precio de venta: 279 €. Precio original: 329 €. Descuento: 15%. "
  */
  const getFormattedPriceReadable = (price) => {
    if (!price) return '';
    const unitPrice = price.unit_price ? `${t('product.price.original_price')}: ${getFormattedPrice(price.unit_price)}.` : '';

    if (price.discount) {
      const salePrice = price.unit_sale_price ? `${t('product.price.sale_price')}: ${getFormattedPrice(price.unit_sale_price)}. ` : '';
      const discount  = `${t('product.price.discount')}: ${price.discount}%. `;
      return `${salePrice} ${unitPrice} ${discount}`
    } else if (price.unit_price) {
      return unitPrice;
    }
  };

  return { getFormattedPrice, getFormattedPriceReadable };
}

export default usePriceFormat;